const languages = [
    { name: "English", value: "1" },
    { name: "French", value: "2" },
    { name: "German", value: "3" },
    { name: "Spanish", value: "4" },

    { name: "Arabic", value: "5" },
    { name: "Chinese", value: "6" },
    { name: "Japanese", value: "7" },
    { name: "Hebrew", value: "8" },
    { name: "Hindi", value: "9" },
    { name: "Russian", value: "10" }
];

export { languages };
